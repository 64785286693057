import React from "react"
import theme from "theme"
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"menu"} />
      <Helmet>
        <title>Our Menu | The Cozy Brew</title>
        <meta
          name={"description"}
          content={"Savor the Craft of Coffee Mastery"}
        />
        <meta property={"og:title"} content={"Our Menu | The Cozy Brew"} />
        <meta
          property={"og:description"}
          content={"Savor the Craft of Coffee Mastery"}
        />
        <meta property={"og:image"} content={"https://civoxenplace.com/img/1.jpg"} />
        <link
          rel={"shortcut icon"}
          href={"https://civoxenplace.com/img/coffee-beans.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://civoxenplace.com/img/coffee-beans.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://civoxenplace.com/img/coffee-beans.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://civoxenplace.com/img/coffee-beans.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://civoxenplace.com/img/coffee-beans.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://civoxenplace.com/img/coffee-beans.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 0px"
          justify-content="center"
          lg-order="1"
        >
          <Text
            margin="0px 0px 24px 0px"
            color="--dark"
            font="--headline1"
            lg-text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          >
            Your Culinary Journey at The Cozy Brew
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD3"
            font="--lead"
            lg-text-align="center"
          >
            Dive into our diverse menu crafted to cater to all tastes and
            preferences. From classic coffees that awaken the senses, to
            innovative beverages that surprise, and delightful treats that
            complement each sip, there's something here for everyone. We invite
            you to explore our offerings, where each item tells a story of
            quality and creativity. Whether you're seeking comfort, a burst of
            energy, or a new flavor experience, The Cozy Brew promises a
            memorable visit every time.
          </Text>
          <Link
            href="/contacts"
            padding="12px 24px 12px 24px"
            color="--light"
            text-decoration-line="initial"
            font="--lead"
            border-radius="8px"
            margin="0px 16px 0px 0px"
            transition="background-color 0.2s ease-in-out 0s"
            sm-margin="0px 0px 16px 0px"
            sm-text-align="center"
            background="--color-primary"
            hover-transition="background-color 0.2s ease-in-out 0s"
            hover-background="--color-primary"
          >
            Contact Us
          </Link>
        </Box>
        <Box
          display="flex"
          width="50%"
          justify-content="flex-end"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 32px 0px"
          margin="0px 0px 0px 0px"
          padding="0px 0px 0px 32px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
        >
          <Image
            src="https://civoxenplace.com/img/4.jpg"
            object-fit="cover"
            width="100%"
            height="100%"
            border-radius="24px"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            min-height="600px"
            max-width="480px"
            sm-min-height="100vw"
          />
        </Box>
      </Section>
      <Section padding="0 0 0 0" quarkly-title="Statistics-7">
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          md-flex-direction="column"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="40%"
            md-width="100%"
            md-margin="0px 0px 50px 0px"
            sm-margin="0px 0px 35px 0px"
          >
            <Image
              src="https://civoxenplace.com/img/5.jpg"
              display="block"
              width="auto"
              height="100%"
              max-width="100%"
              object-fit="cover"
              object-position="30% 50%"
              md-height="600px"
              sm-height="400px"
            />
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            width="60%"
            padding="40px 0px 0px 90px"
            display="flex"
            flex-direction="column"
            justify-content="center"
            lg-padding="0px 0px 0px 50px"
            md-width="100%"
            md-padding="0px 35px 80px 35px"
            sm-padding="0px 0 80px 0"
          >
            <Text
              margin="0px 0px 30px 0px"
              color="--dark"
              font="normal 700 42px/1.2 --fontFamily-sans"
            >
              Menu
            </Text>
            <Box
              min-width="100px"
              margin="30px 0px 30px 0px"
              display="grid"
              grid-template-columns="repeat(2, 1fr)"
              lg-margin="50px 0px 0px 0px"
              md-margin="40px 0px 0px 0px"
              sm-grid-template-columns="1fr"
              sm-grid-template-rows="auto"
            >
              <Box
                min-width="100px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 30px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Coffee Classics
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Our traditional selections celebrate the art of coffee making:
                  <br />
                  <br />
                  Espresso: Intense and rich, made from specially selected
                  beans.
                  <br />
                  <br />
                  Americano: Smooth and robust, a timeless choice for coffee
                  purists.
                  <br />
                  <br />
                  Cappuccino: Perfectly frothy, with a delicate balance of
                  espresso and velvety milk.
                </Text>
              </Box>
              <Box
                min-width="100px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 30px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Specialty Brews
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Experience our innovative takes on coffee:
                  <br />
                  <br />
                  Vanilla Sky Latte: A sweet twist on the classic, with homemade
                  vanilla syrup.
                  <br />
                  <br />
                  Caramel Macchiato: Layered flavors of espresso, foamed milk,
                  and caramel.
                  <br />
                  <br />
                  Mocha Lush: A luxurious blend of cocoa and espresso, topped
                  with whipped cream.
                </Text>
              </Box>
              <Box
                min-width="100px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 30px 0px"
                min-height="80px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Tea Time Favorites
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Not just a coffee house, we also serve a fine selection of
                  teas:
                  <br />
                  <br />
                  Green Tea Zen: Antioxidant-rich and subtly refreshing.
                  <br />
                  <br />
                  Chamomile Calm: Soothing and gentle, perfect for relaxation.
                  <br />
                  <br />
                  Earl Grey Classic: A distinguished blend with hints of
                  bergamot.
                </Text>
              </Box>
              <Box
                min-width="100px"
                min-height="80px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 35px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Seasonal Specials
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Join us for unique creations that change with the seasons:
                  <br />
                  <br />
                  Pumpkin Spice Latte: Autumn in a cup, with real pumpkin and
                  spices.
                  <br />
                  <br />
                  Winter Peppermint Mocha: Invigorating peppermint paired with
                  chocolate.
                  <br />
                  <br />
                  Spring Blossom Tea: Floral and light, celebrating the
                  freshness of spring.
                </Text>
              </Box>
              <Box
                min-width="100px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 30px 0px"
                min-height="80px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Light Bites
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Complement your drink with our selection of snacks:
                  <br />
                  <br />
                  Freshly Baked Croissants: Buttery and flaky, baked daily.
                  <br />
                  <br />
                  Artisanal Sandwiches: Made with gourmet ingredients on freshly
                  baked bread.
                  <br />
                  <br />
                  Seasonal Fruit Parfaits: Fresh, vibrant, and topped with
                  creamy yogurt.
                </Text>
              </Box>
              <Box
                min-width="100px"
                min-height="80px"
                padding="0px 0px 0 15px"
                border-width="0 0 0 1px"
                border-style="solid"
                border-color="#d4d5db"
                margin="0px 0px 35px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="normal 600 20px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                >
                  Additional Services
                </Text>
                <Text
                  margin="15px 0px 0px 0px"
                  color="#75767d"
                  font="300 18px --fontFamily-sansHelvetica"
                >
                  Beyond beverages, The Cozy Brew is a venue for community and
                  creativity:
                  <br />
                  <br />
                  Coffee Workshops: Learn the secrets of coffee making from our
                  expert baristas.
                  <br />
                  <br />
                  Book Swaps: Share and discover new reads within our community.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="80px 0 80px 0"
        sm-padding="60px 0px 60px 0px"
        quarkly-title="Advantages/Features-24"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          sm-min-width="280px"
        />
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          justify-content="flex-start"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          lg-margin="0px 0px 60px 0px"
          sm-margin="0px 0px 40px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-flex-direction="row"
          lg-flex-wrap="wrap"
        >
          <Text
            margin="0px 0px 48px 0px"
            color="--dark"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            lg-width="100%"
          >
            Craft Your Perfect Visit
          </Text>
          <Box
            display="flex"
            align-items="flex-start"
            margin="0px 0px 32px 0px"
            lg-width="100%"
            md-width="100%"
            md-margin="0px 0px 32px 0px"
            md-padding="0px 0px 0px 0px"
          >
            <Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
              <Text
                margin="0px 0px 0px 0px"
                color="--greyD3"
                font="--base"
                lg-text-align="left"
              >
                Let The Cozy Brew be your retreat for great coffee, delightful
                treats, and a warm atmosphere. Discover your next favorite menu
                item with us today!
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
